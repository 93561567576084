module.exports =  {
    name: 'hear',
    iconPaths: {
        favicon32: 'hear/images/favicons/favicon-32x32.png',
        favicon16: 'hear/images/favicons/favicon-16x16.png',
        appleTouchIcon: 'hear/images/favicons/apple-touch-icon-152x152.png',
        maskIcon: 'hear/images/favicons/safari-pinned-tab.svg',
        msTileImage: 'hear/images/favicons/msapplication-icon-144x144.png'
    },
    themeColor: '#0ca47f',
}
