// todo: use language modules



let messages = {
    test: {
        test2: 'bogle test text'
    }
};



export default messages;
