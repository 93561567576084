export default {
   saffronUser : {
      getCurrentUserUrl : 'user/current',
      getRefreshTokenUrl : 'token/refresh',
      getJwtBaseUrl : 'token/jwt',
      // when we can not reconnect a user, should we accept it silently (true), or also inform the user (false)
      newSessionReconnectSilentErrors: true,
      defaultLogoutRedirect : {name: 'index'}
   }
}
