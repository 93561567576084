let isHtml = (arg) =>  /<\/?[a-z][\s\S]*>/i.test(arg);

export default {
    getModalAdapter : (instance) => {
        let promiseModal = (content, title, options) => {
            return new Promise((fulfil, reject) => {
                instance.$saffronComponent
                         .registerModal({content, title, options, fulfil, reject
                });
            });
        }
        
        let getModalDefaultLanguageStrings = (type) => {
            let defaults = {
                okButton : 'core.ui.modal.okButtonText',
                cancelButton : 'core.ui.modal.cancelButtonText',
            };
            switch(type) {
                case 'prompt':
                    defaults.title = 'core.ui.modal.promptTitle';
                    break;
                case 'confirm':
                    defaults.title = 'core.ui.modal.confirmTitle';
                    break;
            
                case 'alert':
                default:
                    defaults.title = 'core.ui.modal.alertTitle';
                    break;
            }
        
            return defaults;
        };
        
        let parseModalArgs = (content, title, options, type = 'alert') => {
            // {okButton, cancelButton, title (based on type)
            let languageKeys = getModalDefaultLanguageStrings(type);
        
            // text translation if not HTML, else used as is
            if ( ! isHtml(content)) {
                content = instance.safeTranslate(content);
            }
        
            // title default if missing
            if(typeof title !== 'string') {
                title = languageKeys.title
            }
        
            // title translation
            if ( ! isHtml(title)) {
                title = instance.safeTranslate(title);
            }
        
            // defaults for options
            if (typeof options !== 'object' || options === null) {
                options = {};
            }
        
            // options labels integrity - default button text
            let defaultLabels = {
                okButton : languageKeys.okButton,
                cancelButton : languageKeys.cancelButton,
            }
            
            if (typeof options.labels !== 'object') {
                options.labels = {}
            }
    
            options.labels = {...defaultLabels, ...options.labels};
            // translate labels
            Object.keys(options.labels).forEach( (key) => {
                options.labels[key] = instance.translate(options.labels[key]);
            });
        
            // support both ok and okButton, cancel and cancelButton
            options.labels['ok'] = options.labels['ok'] || options.labels['okButton'];
            options.labels['cancel'] = options.labels['cancel'] || options.labels['cancelButton'];
        
            
            // assign the title to the options, thats the API from UIkit
            let finalTitle = options.title || title;
    
            // note the type
            options.type = type;
            
            return {finalContent:content, finalTitle: title, finalOptions: options}
        };
    
        return {
            alert: (content, title = null, options = {}) => {
                let {finalContent, finalTitle, finalOptions} = parseModalArgs(content,title, options, 'alert');
                return promiseModal(finalContent, finalTitle, finalOptions);
            },
            confirm : (content, title = null, options = {}) => {
                let {finalContent, finalTitle, finalOptions} = parseModalArgs(content,title, options, 'confirm');
                return promiseModal(finalContent, finalTitle, finalOptions);
            },
            prompt  : (content, title = null, options = {}) => {
                let {finalContent, finalTitle, finalOptions} = parseModalArgs(content,title, options, 'prompt');
              
                // field options
                let optionFieldOptions = (options && typeof options.field === 'object') ? options.field : {};
                    let fieldOptions = {
                      //  label: 'core.ui.modal.defaultPromptFieldLabel',
                    //    testProp: 'testValue',
                        ...optionFieldOptions
                    };
                return promiseModal(finalContent, finalTitle, {...finalOptions, ...{field: fieldOptions}});
            },
            show : () => {},
            hide : () => {},
        }
    
    },
    getNotificationAdapter: (instance) => {
        let notificationAdapter =  (content = null, theme = 'default', positionOrOptions = {})=>  {
            let promiseNotification = (options) => {
                return new Promise((fulfil, reject) => {
                    instance.$saffronComponent.registerNotification({...options, fulfil, reject});
                });
            }
            
            let getFinalTheme = (defaultValue) => {
                if (positionOrOptions && typeof positionOrOptions === 'object' && positionOrOptions.theme) {
                    return positionOrOptions.theme;
                }
                
                if (typeof theme === "string") {
                    return theme;
                }
                
                return defaultValue;
            }
            
            let getFinalNotificationConfig = (defaultConfig) =>  {
                let conf = defaultConfig;
                
                // support theme
                conf.theme = getFinalTheme(defaultConfig.theme);
                
                if (positionOrOptions === 'snackbar') { //it is binary, so only "snackbar" matters.
                    conf.location = 'snackbar'
                }
    
                // support position from options. needs to be renamed
                if (positionOrOptions && typeof positionOrOptions.position === 'string') {
                    conf.location = positionOrOptions.position
                }
    
                // default icon and title based on theme
                switch (conf.theme) {
                    case 'default':
                    case 'lead':
                    case 'lead-2':
                        conf.icon = 'warning-circle';
                        conf.title = 'core.notification.defaultGeneralTitle';
                        break;
                    case 'success':
                        conf.icon = 'check';
                        conf.title = 'core.notification.defaultSuccessTitle';
                        break;
                    case 'warning':
                        conf.icon = 'warning-triangle';
                        conf.title = 'core.notification.defaultWarningTitle';
                        break;
                    case 'danger':
                    case 'error':
                        conf.icon = 'warning-triangle';
                        conf.title = 'core.notification.defaultErrorTitle';
                        break;
                    default:
                        break;
                }
                
                // custom default animation for snackbar position
                if (conf.location === 'snackbar') {
                    conf.animation = 'flipInX';
                    conf.closeAnimation = 'flipOutX';
                }
                
                // merge/override options arg if it is an object
                if (positionOrOptions && typeof positionOrOptions === 'object') {
                    conf = {...conf, ...positionOrOptions};
                }
                
                return conf;
            }
    
            let defaultConfig =  {
                theme: 'default',
                content: content,
                contentParams: {},
                title: false,
                titleParams: {},
                icon : false,
                autoClose: true,
                location: 'notifications'
            };
            return promiseNotification(getFinalNotificationConfig(defaultConfig));
        }
        notificationAdapter.closeAll = () => {
            instance.$saffronComponent.closeAllNotifications();
        };
        notificationAdapter.clearAll = () => {
            instance.$saffronComponent.closeAllNotifications();
        };
        return notificationAdapter;
    },
    getGlobalSpinnerAdapter : (instance) => {
        return {
            show(payload) {
                if  ( ! instance.$store) {
                    return false;
                }
                return instance.$store.commit('ui/showGlobalSpinner', payload);
            },
            hide(payload) {
                if  ( ! instance.$store) {
                    return false;
                }
                return instance.$store.commit('ui/hideGlobalSpinner', payload);
            },
            isActive(state) {
                if  ( ! instance.$store) {
                    return false;
                }
                return instance.$store.getters['ui/isGlobalSpinnerActive'];
            },
        };
    },
    getDropdownAdapter : () => {
        return {};
    }
}
