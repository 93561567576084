

export default {
    'defaultRequestAdapter' : 'saffronAxios',
    'defaultResponseAdapter' : 'saffron',
    requestAdapters : {
        saffronAxios : {
            rootUrl : process.env.VUE_APP_AXIOS_ROOT_URL || '/api/',
        }
    },
    responseAdapters : {
        saffron : {

        }
    }
}
