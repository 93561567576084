const cookies = require('js-cookie');
import _ from 'lodash/object';

const plugin =  {
    that :this,
    defaultConfig : {
        sameSite: 'None',
        expires : Number(config.cookie.expires),
        path    : config.cookie.path,
        secure: true
    },
    getApi(target, options = {}) {
        let  { set, get, remove, defaultConfig} = plugin;
        plugin.defaultConfig = _.merge(defaultConfig, options);

        return {set, get, remove, defaultConfig};
    },
    set (key, value, configArgument) {
        let finalConfig = {...plugin.defaultConfig};
        if (configArgument && typeof configArgument === 'object') {
            finalConfig = {...finalConfig, ...configArgument};
        }

        return cookies.set(key, value, finalConfig);
    },
    get (key, defaultValue = null) {
        let val =  cookies.get(key);
        if (typeof val === 'undefined') {
            return defaultValue;
        }

        return val;
    },
    remove (name) {
        return cookies.remove(name);
    },
    delete (name) {
        return cookies.remove(name);
    }
};

let api = plugin.getApi();
export default api;
