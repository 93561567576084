import cookie from '@/client/utilities/cookie.js';

let adminCookieName = 'ariel-cookie-admin';

export default {
    state: () => {
        let cookieIsAdmin = cookie.get(adminCookieName) && cookie.get(adminCookieName) !== 'false';
        return {
            admin : {
                isAdmin : cookieIsAdmin,
                pin: 1234
            }
        }
    },
    mutations: {
        setIsAdmin : (state, payload) => {
            // cast to bool (cookie value may be assigned as "true" or "false"
            if (payload === true || payload === 1 || payload === '1' || payload === 'true' ) {
                payload = true;
            } else {
                payload = false;
            }
        
            payload = Boolean(payload);
            state.admin.isAdmin = payload;
            console.log('set cookie', payload);
            cookie.set(adminCookieName, payload);
        },
    },
    actions: {
   
    },
    getters: {
        isAdmin : (state) => state.admin.isAdmin,
        isPinCorrect : (state) => (pin) => {
            return Number(pin) === Number(state.admin.pin)
        }
    }
};
