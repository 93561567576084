export default {
    namespaced: true,
    state : () => {
        return {
            subjectData : {
                name: '',
                age: '',
                sex: ''
            },
            subjectDataTranslations : {
                he: {
                    name : 'שם',
                    age: 'גיל',
                    sex: 'מין'
                }
            }
        }
    },
    getters : {
        subjectData : (state) =>  state.subjectData,
        subjectDataTranslations : (state) => (key) => {
            return state.subjectDataTranslations[key];
        },
        hasSubject  : (state) =>  {
            return (typeof state.subjectData.name === 'string' && state.subjectData.name.length > 1);
        }
    },
    mutations: {
        updateSubjectData: (state, payload) => {
            state.subjectData = payload;
        },
        resetSubject : (state) => {
            state.subjectData =  {
                name: '',
                age: '',
                sex: ''
            }
        }
    }
};
