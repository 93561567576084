// todo support options for request and response adapters
import {ref, reactive,watchEffect} from 'vue';
import {useStore} from 'vuex';
let captchaLoaded = ref(false);
let publicKey = null;

let lazyLoadCaptcha = async () => {
    if ( utilities.isSSR()) {
        return false;
    }

    if (captchaLoaded.value) {
        return true;
    }

    return new Promise (fulfil => {
        document.body.appendChild(Object.assign(document.createElement('script'), {
            type: 'text/javascript',
            nonce : '{NONCE}',
            src: 'https://www.google.com/recaptcha/api.js?render='+publicKey,
            onload: () => {
                captchaLoaded.value = true;
                fulfil();
            }
        }));
    });

};

export default (props, storeOverride, options) => {
    if ( ! props) {
        props = {};
    }

    let isCaptchaReady = ref(captchaLoaded);

    let setPublicKey = () => {
        let store = useStore();
        let key = null;

        // if we have store use it to get config. if not, access config directly
        if (store) {
            key = store.getters['config/get']('security.recaptcha.publicKey')
        } else {
            key = config.security.recaptcha.publicKey;
        }

        // oh no... config didnt have a good key. lets use env directly as a last resort
        if ( ! key || key === '') {
            key = process.env.VUE_APP_SECURITY_RECAPTCHA_PUBLIC_KEY;
        }

        publicKey = key;
    }

    if ( ! publicKey) {
        setPublicKey();
    }

   // lazyLoadCaptcha().then();

    let parseOptions = (options) => {
        if (typeof options !== 'object' || ! options ) {
            return {}
        }
        // right now we dont implement anything. but in the future we can have multiple captchas, and then we will need to do stuff
        return options;

    };

    let executeCaptcha =  async (options) => {
        let noApiResponse =  {
            isError: true,
            errorText: 'No recaptcha API',
            token: false
        };

        if (utilities.isSSR()) {
            return noApiResponse;
        }
        
        // await loading of captcha API
        await lazyLoadCaptcha();

        if ( ! captchaLoaded) {
            await new Promise (async (fulfil) => {
                watchEffect(() => {
                    if (captchaLoaded.value === true) {
                        fulfil();
                    }
                });
            });
        }

        // still somehow no API? we cant fix that
        if ( ! grecaptcha) {
            return noApiResponse
        }

        // run the challenge
        let token
        try {
            await new Promise((fulfil) => {
                grecaptcha.ready(async () => {
                    console.log('ready - before execute');
                    token = await grecaptcha.execute(publicKey, parseOptions(options));
                    fulfil();
                });
            });
        } catch (e) {
            return {
                isError: true,
                errorText: 'captcha execute exception',
                token: null
            }
        }
        
        // return token
        return {
            isError: false,
            errorText: '',
            token: token
        }
    };

    return {
        executeCaptcha,
        isCaptchaReady
    };
}
