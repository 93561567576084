export default {
    frameworks         : ['we', 'animate'],
    frameworkComponents: ['we'],
    scss               : ['styles'],
    responsive         : {
        classMap             : {
            xs: 400,
            s : 500,
            m : 767,
            l : 1024,
            xl: 1300,
        },
        responsiveClassPrefix: 'break-',
        responsiveClassMode  : 'mobileFirst',
    },
    defaultIconSet     : 'rubicons',
    dropdown: {
        animationClass : 'animate__zoomIn animate--fastest',
        gap: 10
    },
    weUI: {
        notification: {
            defaultCloseAnimation: 'fadeOut',
            defaultDuration: 5000,
        },
        button : {
            defaultTheme: 'lead'
        },
        tooltip: {
            animationClassIn: 'animate__fadeIn',
            animationClassOut: 'animate__fadeOut',
            extraClass: 'animate--faster'
        }
    },
    pageTransitionClassIn : 'animate__fadeIn animate--fast',
    pageTransitionClassOut: 'animate__fadeOut  animate--faster',
}
