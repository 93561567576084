// TODO: get rid of this coupling
import useRecaptcha from '@/client/extensions/composition/useRecaptcha.js';

import {ref, unref, reactive, computed, watchEffect, nextTick, inject, getCurrentInstance} from 'vue';

const _ = require('lodash/object');

/*
 
 // loading ui support
 // loading component - spinner - local or fixed
 // loading component - skeleton - bars, circles, squaers & prefixes (ie circle + lines etc)
 // loading component - progress bar
 */
async function getRequestAdapter(asyncPropOrName) {
    let finalName = null;
    
    if (typeof asyncPropOrName === 'string') {
        finalName = asyncPropOrName;
    }
    
    if (typeof asyncPropOrName === 'object' && asyncPropOrName !== null) {
        finalName = asyncPropOrName.requestAdapter || false;
    }
    
    
    if ( ! finalName || finalName === 'default' || finalName === 'Default') {
        finalName = config.asyncData.defaultRequestAdapter;
    }
    
    let adapter = await import(/* webpackChunkName: "requestAdapter" */ `@/client/extensions/composition/asyncOperations/requestAdapters/${finalName}`);
    return await adapter.default();
}

async function getResponseAdapter(asyncPropOrName) {
    
    let finalName = null;
    
    if (typeof asyncPropOrName === 'string') {
        finalName = asyncPropOrName;
    }
    
    if (typeof asyncPropOrName === 'object' && asyncPropOrName !== null) {
        finalName = asyncPropOrName.responseAdapter || false;
    }
    
    
    if ( ! finalName || finalName === 'default' || finalName === 'Default') {
        finalName = config.asyncData.defaultResponseAdapter;
    }
    
    let adapter = await import(/* webpackChunkName: "responseAdapter" */ `@/client/extensions/composition/asyncOperations/responseAdapters/${finalName}`);
    return await adapter.default();
}


import {useStore} from 'vuex'
// todo: response adapters

// TODO: SSR:  this is a problem. this wont work with SSR, but we dont want to inject store everytime we use this
let mainStoreInstance = false;

// TODO: SSR  this is a problem. the global needs to sit somewhere ON THE APP  - module-wide things are ok for client but not for SSR
const globalLoadingInstances = reactive({});


const socketsComposition = (props, incomingOptions = {}) => {
    let saffronSocketSymbol = Symbol('saffron-socket-symbol');
    let store               = false;
    let saffronGlobal       = false;
    let socketRootUrl       = process.env.VUE_APP_SOCKET_ROOT_URL;
    let options;
    
    // setup methods
    let populateAndHandleSafeOptions = () => {
        options = incomingOptions && typeof incomingOptions === 'object' ? incomingOptions : {};
        if (options.url && typeof options.url === 'string') {
            socketRootUrl = options.url;
        }
        
        
        return true;
    };
    
    let populateSaffronGlobal        = () => {
        if (getCurrentInstance()) { // inject sockets to global app state
            saffronGlobal = inject(config.saffronAppGlobalKey);
        }
        
        // override with options saffron global if given
        if (options.saffronGlobal) {
            saffronGlobal = options.saffronGlobal;
        }
        
        // at the moment, we can not continue. because socket inflation may occur as we can not cache the sockets
        if ( ! saffronGlobal) {
            
            return false;//TODO: allow access to sockets anyway?
        }
        
        // create global sockets in saffron, if missing
        if ( ! saffronGlobal.sockets) {
            saffronGlobal.sockets = {};
        }
        
        // create socket queue
        if ( ! saffronGlobal.socketCreationQueue) {
            saffronGlobal.socketCreationQueue = {};
        }
        
        return true;
    };
    
    let populateStore                = () => {
        if (getCurrentInstance()) { // inject sockets to global app state
            store = useStore();
        }
        
        // options override: store
        if (options.store) {
            store = options.store;
        }
        
        return true;
    };
    
    let setup                        = () => {
        populateAndHandleSafeOptions();
        populateStore();
        return populateSaffronGlobal(); // only this can "fail"
    };
    
    // do not provide anything if setup fails. this will only happen if we are not in a component setup AND did not provide saffronGlobal & store in options
    if ( ! setup()) { // at the moment, we require saffron global to operate. otherwise - we can not track the sockets in one singleton (and probably cant set auth to them)
        return false;
    }
    
    // socket management methods
    const isSocketPendingCreate   = (name) => {
        return saffronGlobal.socketCreationQueue.hasOwnProperty(name);
    }
    
    const hasSocket               = (name) => {
        return saffronGlobal.sockets.hasOwnProperty(name);
    };
    
    const createDefaultSocket     = async () => {
        return createSocket('default', {});
    }
    
    const lazyCreateDefaultSocket = async () => {
        if (hasSocket('default')) {
            return saffronGlobal.sockets.default;
        }
        
        if (isSocketPendingCreate('default')) { // promise from the queue
            return saffronGlobal.socketCreationQueue.default;
        }
        
        return createDefaultSocket();
    }
    
    // method to get a socket proxy, overloaded with out good stuff
    const getSocketProxy = (socket, name) => {
        // overload  socket with our stuff
        socket[saffronSocketSymbol] = reactive({
                                                   uuid      : ref(socket.id), // initial id - this is unique, and corresponds to a socket in the backend, but may correspond to an old close socket in the backend, which we do not want
                                                   hasUuid   : false,
                                                   name      : name,
                                                   socketName: name,
                                                   waitForUUID : () => {
                                                       if (socket[saffronSocketSymbol].uuid) {
                                                           return socket[saffronSocketSymbol].uuid;
                                                       }
                                                       return new Promise((resolve, reject) => {
                                                           watchEffect(() => {
                                                               if (socket[saffronSocketSymbol].uuid) {
                                                                   resolve(socket[saffronSocketSymbol].uuid);
                                                               }
                                                           });
                                                       });
                                                   }
                                               });
        
        const socketProxyHandler = {
            get(target, prop, receiver) {
                
                // set uuid
                if (prop === 'setUUID') { // set uuid
                    return function (uuid) {
                        socket[saffronSocketSymbol].uuid    = ref(uuid);
                        socket[saffronSocketSymbol].hasUuid = true;
                        return receiver;
                    }
                }
                
                if (prop === 'socketUUID') {
                    return socket[saffronSocketSymbol].socketUUID
                }
                
                // saffron data overload
                if (socket[saffronSocketSymbol].hasOwnProperty(prop)) {
                    return socket[saffronSocketSymbol][prop];
                }
                
                // original object behaviour
                if (target.hasOwnProperty(prop)) {
                    return target[prop]
                }
                
                
                return target[prop];
            }
        };
        
        return new Proxy(socket, socketProxyHandler);
    };
    
    // todo: clean this up
    const createSocket = async (nameInput = 'default', options = {}) => {
        let socketConfig     = {};
        let socketUrl        = socketRootUrl;
        let name             = nameInput && typeof nameInput === 'string' ? nameInput : 'default';
        let namespace        = '';
        
        let implementOptions = () => {
            // default options
            if (typeof options !== 'object' || ! options) {
                options = {};
            }
            
            // options token override
            if (options.token) {
                socketConfig.auth = {
                    token: options.token
                }
            }
            
            // options url override
            if (options.url) {
                socketUrl = options.url;
            }
            
            if (options.namespace && typeof options.namespace === 'string') {
                namespace = options.namespace;
            }
        }
        
        // automatically provide token through store, if possible
        
        
        if (store) {
            
            if ( ! store.getters['user/token']) {
                await store.dispatch('user/refreshJwt')
            }
            socketConfig.auth = {
                token: store.getters['user/token']
            }
        }
        
        // overrides store and url, etc
        implementOptions();
        
        // do not provide existing sockets as if they were created
        if (hasSocket(name)) {
            debug('can not create socket with this name, already exists.', 2, name);
            return false;
        }
        
        // promise a socket, to the queue
        saffronGlobal.socketCreationQueue[name] = new Promise(async (fulfil, reject) => {
            // create the socket
            let {io}     = await import('socket.io-client');
            const socket = io(socketUrl + namespace, socketConfig);
            
            saffronGlobal.sockets[name] = getSocketProxy(socket, name);
            
            // TODO: consider improving this: only fetch jwt if the demand request has a longer lifetime demand than what we have
            // socket behavior: provides auth token
            socket.on('saffronUser:demandAuthToken', async (data) => {
                let token = false;
                
                if (store) {
                    await store.dispatch('user/refreshJwt');
                    token = store.getters['user/tokenType']
                }
                
                // noinspection JSCheckFunctionSignatures
                socket.emit('saffronUser:provideAuthToken', token);
            });
            
            // socket behaviour: receives and tracks own uuid
            socket.on('saffron:setSocketUUID', async (uuid) => {
                // noinspection JSUnresolvedFunction - this exists in our proxy. chillax.
                saffronGlobal.sockets[name].setUUID(uuid);
            });
            
            // keep providing JWT to backend to keep our authentication
            if (store) {
                watchEffect(() => {
                    let token =   store.getters['user/token'];
                    socket.emit('saffronUser:provideAuthToken', token);
                });
            }
            
            fulfil(saffronGlobal.sockets[name]);
        });
        
        // also return this promise
        return saffronGlobal.socketCreationQueue[name];
    }
    
    // sockets proxy handler: access to sockets - this is our final export
    const socketsGlobalProxyHandler = {
        get(target, prop, receiver) {
            // support vue symbol accessors
            if (typeof prop === 'symbol') {
                return target[prop];// which can be undefined
            }
            
            // expose a dummy init method, to prepare a default socket if required
            if (prop === 'init' || prop === 'initialize') {
                return lazyCreateDefaultSocket;
            }
            
            // expose create socket
            if (prop === 'create' || prop === 'createSocket') {
                return createSocket;
            }
            
            // give existing socket / properties if available
            if (target.hasOwnProperty(prop)) {
                return target[prop];
            }
            
            
            // catch all - normal object behaviour (undefined)
            if ( target.hasOwnProperty(prop)) {
                return target[prop];
            }
            
            if ( target.hasOwnProperty('default')) {
                return target.default[prop];
            }
            return undefined;
        }
    };
    
    return new Proxy(saffronGlobal.sockets, socketsGlobalProxyHandler);
}


export default (props, storeOverride, options) => {
    let instanceId = utilities.getUniqueNumber();
    
    if (typeof options === 'object' && options && options.mainStoreInstance) {
        mainStoreInstance = options.mainStoreInstance;
    }
    
    if (typeof props !== 'object' || props === null) {
        props = {};
    }
    
    
    let store;
    
    if (getCurrentInstance()) {
        store = useStore();
    } else { // TODO: on ssr this solution causes issue. we need to send requests with user data from store (JWT token), but the global store is module-wide and will cause horrors in SSR
        store = storeOverride || mainStoreInstance;
    }
    
    
    let authToken = computed(() => {
        if ( ! store) {
            return '';
        }
        
        return store.getters['user/token'];
    });
    
    let authTokenType = computed(() => {
        if ( ! store) {
            return '';
        }
        
        return store.getters['user/tokenType'];
    });
    
    let statusCheckTrigger = false;
    
    // reactive default request and response adapters, exposed to composition caller
    let defaultRequestAdapter  = ref({value: null});
    let defaultResponseAdapter = ref({value: null});
    
    // ready status for async operations. exposed to composition caller
    let asyncOpsReady = ref({value: false});
    
    // list of current async tasks. used internally
    let runningAsyncTasks = reactive({});
    
    let runningAsyncDataRequests = reactive({});
    
    // are we performing an sync operation right now? exposed to composition caller
    let asyncStatus = computed(() => {
        let loading             = false;
        let asyncDataLoading    = false;
        let asyncDataClearFinal = true;
        let asyncDataClear1     = true;
        let asyncDataClear2     = true;
        
        // update loading state, local and global
        Object.keys(runningAsyncTasks).forEach((key) => {
            if (runningAsyncTasks[key]) {
                loading = true;
            }
        });
        
        // update global state
        globalLoadingInstances[instanceId] = loading;
        
        // compute anyLoading - is any instance of asyncOps working at the moment?
        let temp       = Object.values(globalLoadingInstances || {});
        let anyLoading = Array.isArray(temp) && temp.includes(true);
        
        // update async data loading
        Object.keys(runningAsyncDataRequests).forEach((key) => {
            if (runningAsyncDataRequests[key]) {
                asyncDataLoading = true;
            }
        });
        
        /**
         * When async data seems to be ready, cascade through 2 cycles. if it is still ready, declare it as "cleared"
         */
        // when async data is loading -
        watchEffect(() => {
            // if loading - than async data is not clear
            if (asyncDataLoading) {
                asyncDataClearFinal = false;
                asyncDataClear1     = false;
                asyncDataClear2     = false;
            } else { // trigger watcher for asyncDataClear1 on next cycle
                nextTick(() => {
                    asyncDataClear1 = true;
                });
            }
        });
        
        // async data was fetched one cycle ago.
        watchEffect(() => {
            if (asyncDataLoading) {
                asyncDataClearFinal = false;
                asyncDataClear1     = false;
                asyncDataClear2     = false;
            } else { // trigger watcher for asyncDataClear1 on next cycle
                nextTick(() => {
                    asyncDataClear2 = true;
                });
            }
        });
        
        // async data was fetched two cycles ago
        watchEffect(() => {
            if (asyncDataLoading) {
                asyncDataClearFinal = false;
                asyncDataClear1     = false;
                asyncDataClear2     = false;
            } else { // trigger watcher for asyncDataClear1 on next cycle
                nextTick(() => {
                    asyncDataClearFinal = true;
                    
                });
            }
        });
        
        return {
            loading,
            globalLoadingInstances,
            anyLoading,
            asyncDataLoading,
            asyncDataClear: asyncDataClearFinal,
            asyncDataReady: asyncDataClearFinal,
        };
    });
    
    // our internal service status tracker. when all are ready, asyncOpsReady changes
    let serviceStatus = {
        requestAdapter : false,
        responseAdapter: false,
    };
    
    // queue to allow us to execure async ops requested, only when we are ready
    let queue = [];
    
    // method to execute the task queue
    let executeQueue = () => {
        queue.forEach((val) => {
            val();
        });
        
        queue = [];
    };
    
    // method to run task if able, or put it in queue to run when able
    let executeWhenAble = function (callback) {
        
        if (asyncOpsReady.value === true) {
            callback();
        } else {
            queue.push(callback);
        }
    };
    
    // watch our ready status. runs queue if needed
    watchEffect(() => {
        if (asyncOpsReady.value) {
            executeQueue();
        }
    });
    
    /**
     * Internal method to check if all services are ready
     * @returns {boolean}
     */
    function areAllServicesReady() {
        let ready = true;
        
        for (const [key, value] of Object.entries(serviceStatus)) {
            if ( ! value) {
                ready = false;
            }
        }
        
        
        return ready;
    }
    
    /**
     * Register that one of our async props/functions is ready
     * used to track the ready state of asyncOps
     * @param key
     * @param value
     */
    function updateServiceStatus(key, value) {
        serviceStatus[key] = value;
        
        if (areAllServicesReady()) {
            asyncOpsReady.value = true;
        }
    }
    
    /**
     * Setup default adapters. Allows us to fetch and cache them
     */
    function setupDefaultAdapters() {
        getRequestAdapter(props.asyncDataDefaults).then((result) => {
            defaultRequestAdapter.value = result;
            updateServiceStatus('requestAdapter', true);
        });
        
        getResponseAdapter(props.asyncDataDefaults).then((result) => {
            defaultResponseAdapter.value = result;
            updateServiceStatus('responseAdapter', true);
        });
    }
    
    /**
     * For a named async task, register that it is running
     * used to track async fetch state
     * @param name
     */
    function registerAsyncTaskStart(name) {
        runningAsyncTasks[name] = true;
    }
    
    /**
     * For a named async task, register that it is completed
     * used to track async fetch state
     * @param name
     * @param result
     */
    function registerAsyncTaskEnd(name, result) {
        if (typeof runningAsyncTasks[name] !== 'undefined') {
            // trigger the reactivity
            runningAsyncTasks[name] = false;
            // dont spam hare, delete what's done
            delete (runningAsyncTasks[name]);
        }
    }
    
    /**
     * Make an async call that changes our loading state
     * @param target url for call
     * @param data data for call
     * @param options for call. may include these important keys:
     * 1. method (post/get etc, whatever the request adapter supports)
     * 2. requestAdapter, responseAdapter - name of desriable adapters (or default is used)
     * 3. all options are passed to both adapters, and they can do whatever they want with it
     * @returns {Promise<*>}
     */
    let asyncCall = async (target, data = {}, options = {}) => {
        let getRequestAdapterForRequest  = (name) => {
            return new Promise(async (resolve, reject) => {
                if (typeof name !== 'string') {
                    return resolve(defaultRequestAdapter.value);
                }
                
                // we need custom adapter
                let adapter = await getRequestAdapter(name);
                resolve(adapter);
            });
        };
        let getResponseAdapterForRequest = (name) => {
            return new Promise(async (resolve, reject) => {
                if (typeof name !== 'string') {
                    return resolve(defaultResponseAdapter.value);
                }
                
                // we need custom adapter
                let adapter = await getResponseAdapter(name);
                resolve(adapter);
            });
        };
        let getRequestAdapters           = async (reqAdapterName, resAdapterName) => {
            return await Promise.all([
                                         getRequestAdapterForRequest(reqAdapterName || 'default'),
                                         getResponseAdapterForRequest(resAdapterName || 'default')
                                     ]);
        };
        let runAndAppendCaptchaCode      = async () => {
            let {executeCaptcha} = useRecaptcha();
            let captchaResult    = await executeCaptcha();
            
            if (captchaResult.isError) {
                debug('error appending captcha challenge to asyncCall - recaptcha composition failed to get token', {captchaResult});
                return false;
            }
            
            if (data === null || typeof data === 'undefined') {
                data = {};
            }
            
            if (typeof data !== 'object') {
                // error - we cant automatically add captcha code
                debug('error appending captcha challenge to asyncCall - data object must be undefined, null, or object', {data});
                return false;
            }
            
            data.securityChallenge = captchaResult.token;
            return true;
        }
        
        registerAsyncTaskStart(target);
        
        // overload local
        if (store && typeof options === 'object' && ! options.hasOwnProperty('locale')) {
            options.locale = store.getters['locale/slug'];
        }
        
        // determine method and get adapters. Getting adapters uses promise but it may be resolved immediately
        let method = options.method || 'get';
        
        // overload auth
        if ( ! options.authorization) {
            options.authorization = {
                'tokenType': unref(authTokenType),
                'token'    : unref(authToken)
            }
        }
        
        // overload recaptcha if needed (overloads data)
        if (options && typeof options === 'object' && options.useRecaptcha) {
            await runAndAppendCaptchaCode();
        }
        
        // get the adapters. This may trigger a call to the adapters
        let adapters = await getRequestAdapters(options.requestAdapter, options.responseAdapter);
        
        // execute the request using the request adapter
        let rawResponse = await adapters[0][method](target, data, options);
        
        // parse the response using the response adapter
        let result = await adapters[1].parse(rawResponse, options);
        
        registerAsyncTaskEnd(target, result);
        
        return result;
    };
    
    /**
     * TODO: make this awaitable for SSR
     * Based on component's asyncData property, fetch it's async data
     * @param config
     * @param component the component that will recieve the data
     */
    let fetchAsyncData = (config = null, component) => {
        let asyncDataConfig = (config !== null ? config : component.asyncData);
        let targets         = {};
        
        // helper function to enforce async request config integrity
        function getSafeRequestConfig(requestConfig) {
            let val = null;
            
            // support string key (convert to object
            if (typeof requestConfig === 'string') {
                val = {
                    target : requestConfig,
                    data   : {},
                    options: {}
                }
            }
            
            if (typeof requestConfig === 'object' && requestConfig !== null) {
                val = Object.assign({}, requestConfig);
            }
            
            if (typeof val !== "object" || val === null) {
                debug('Bad argument for fetchAsyncData, should be string or object with a "target" property', 2, requestConfig);
                return false;
            }
            
            // enforce integrity
            if (typeof val.target === 'undefined') {
                debug('Bad argument for fetchAsyncData, should be string or object with a "target" property', 2, requestConfig);
                return false;
            }
            
            // support function
            if (typeof val.data === 'function') {
                val.data = val.data();
            }
            
            // enforce value for data as object
            if (typeof val.data === 'object' && val.data !== null) {
                val.data = _.merge({}, val.data);
            }
            
            if (typeof val.data !== 'object' || val.data === null) {
                val.data = {};
            }
            
            // enforce value for options as object
            if (typeof val.options === 'object' && val.options !== null) {
                val.options = _.merge({}, val.options);
            }
            
            if (typeof val.options !== 'object' || val.options === null) {
                val.options = {};
            }
            
            // check for a store key
            if (typeof val.storeKey !== 'string') {
                val.storeKey = false;
            }
            
            if (typeof val.shouldFetch === 'undefined') {
                val.shouldFetch = true;
            }
            
            return val;
        }
        
        // create valid well formatted call arguments
        Object.keys(asyncDataConfig).forEach((key) => {
            let original = asyncDataConfig[key],
                safeConfig;
            
            safeConfig = getSafeRequestConfig(original);
            if (safeConfig) {
                targets[key] = safeConfig;
            }
        });
        
        // flash the request queue
        Object.keys(runningAsyncDataRequests).forEach(function (key) {
            delete runningAsyncDataRequests[key];
        });
        
        // fetch all the data
        
        Object.keys(targets).forEach((key) => {
            let target = targets[key],
                requestKey;
            
            // skip if the target specified a condition
            if (target.shouldFetch === false || (typeof target.shouldFetch === 'function' && ! target.shouldFetch())) {
                return true;
            }
            requestKey = key + utilities.getUniqueNumber();
            
            // log that the request is running
            runningAsyncDataRequests[requestKey] = true;
            
            // run the request
            let executeCallback = async () => {
                let result = await asyncCall(target.target, target.data, target.options);
             
                // for raw response - assign it, delete the request and we are done
                if (target.options.responseRaw) {
                    component[key] = result;
                    delete (runningAsyncDataRequests[requestKey]);
                    return;
                }
                
                // for normal operation, assign data only in case theres no error. This way we dont assign unexpected values
                if (result.isError) {
                    // request is now finished
                    delete (runningAsyncDataRequests[requestKey]);
                    
                    // log that the request is completed
                    delete (runningAsyncDataRequests[requestKey]);
                    
                }
                
                // allow post processing of data
           
                if (target.options.dataMutator && typeof target.options.dataMutator === 'function') {
                    result.data = target.options.dataMutator(result.data);
                }
    
                // TODO: save this in store and return a store getter for SSR
                if (target.storeKey && store) {
                    store.commit('asyncData/generic', {key: target.storeKey, value: result.data});
                } else {
                    component[key] = result.data;
                }
                
                // request is now finished
                delete (runningAsyncDataRequests[requestKey]);
                
                // log that the request is completed
                delete (runningAsyncDataRequests[requestKey]);
                
                
            };
            
            
            if (utilities.isSSR) {
                executeCallback();
            } else {
                executeWhenAble(executeCallback);
            }
            
        });
    };
    
    // use async call to implement asyncData obtaining via config
    setupDefaultAdapters();
    
    
    let initializedSocketsComposition = socketsComposition(props, options);
    
    // todo: consider: using proxy to allow execution of asyncOps
    let asyncOps = reactive({
                                socket         : initializedSocketsComposition,
                                asyncOpsReady,
                                getRequestAdapter,
                                getResponseAdapter,
                                requestAdapter : defaultRequestAdapter,
                                responseAdapter: defaultResponseAdapter,
                                asyncStatus,
                                asyncCall,
                                call           : asyncCall,
                                fetchAsyncData
                            });
    
    return {
        asyncOpsReady,
        getRequestAdapter,
        getResponseAdapter,
        requestAdapter : defaultRequestAdapter,
        responseAdapter: defaultResponseAdapter,
        asyncStatus,
        asyncCall,
        fetchAsyncData,
        asyncOps,
        socket         : initializedSocketsComposition,
        getSocketComposition: socketsComposition
        
    };
}
