import cookie from '@/client/utilities/cookie.js';

let settingsCookieName = 'ariel-cookie-test-a-settings';
let defaultSettings = {
    baseRatio           : 0,  // initial noise to signal ratio%
    exampleRatio        : 0,  // noise to signal ratio in example %
    initialRoundCount   : 5,    // count of initial steps
    initialRoundStep    : 10,    // change per initial step, decibel
    roundStep           : 5,    // change per step, decibel
    totalRounds         : 25,   // Total Rounds
    viableRounds        : 10,   // Rounds used to calculate the final result (from the end)
    minimumRatio        : -80,   // Minimum signal to noise ratio, db
    maximumRatio        : 80,  // Maximum signal to noise ratio, db
    digitInterval       : 100,  // interval between digits (silent time), ms
    roundInterval       : 500,  // interval between user input to beggining of next round, ms
    numDigits           : 3,
    stepFeedback        : false,
    digitFeedback       : false,
    nextRoundAutoPlay   : true,
    setFeedBackDuration : 3000,
    debugMode           : false,
    skipToTest          : false,
    pureTone            : false,
    stepReplayFeedback      : false,
    baseVolumeAbsolute  : 100,   // base volume of speakers, DB
    baseNoiseVolume     : 100,   // base volume of noise, %
};
let settingsType = {
    baseRatio           : 'number',  // initial noise to signal ratio%
    exampleRatio        : 'number',  // noise to signal ratio in example %
    initialRoundCount   : 'number',    // count of initial steps
    initialRoundStep    : 'number',    // change per initial step, decibel
    roundStep           : 'number',    // change per step, decibel
    totalRounds         : 'number',   // Total Rounds
    viableRounds        : 'number',   // Rounds used to calculate the final result (from the end)
    minimumRatio        : 'number',   // Minimum signal to noise ratio, db
    maximumRatio        : 'number',  // Maximum signal to noise ratio, db
    digitInterval       : 'number',  // interval between digits (silent time), ms
    roundInterval       : 'number',  // interval between user input to beggining of next round, ms
    numDigits           : 'number',
    stepFeedback        : 'boolean',
    digitFeedback       : 'boolean',
    nextRoundAutoPlay   : 'boolean',
    setFeedBackDuration : 'number',
    debugMode           : 'boolean',
    skipToTest          : 'boolean',
    pureTone            : 'boolean',
    stepReplayFeedback      : 'boolean',
    baseVolumeAbsolute  : 'number',   // base volume of speakers, DB
    baseNoiseVolume     : 'number',   // base volume of noise, %
  
}

let getTypedSetting = (key, value) => {
    let type = settingsType[key];
    
    switch(type) {
        case 'Number':
        case 'number':
        case Number:
            return Number(value);
        case 'boolean':
        case 'Boolean':
        case Boolean:
            return Boolean(value);
        default:
           return value
        
    }
}
let enforceSettingsType = (input) => {
    
    for (const [key, value] of Object.entries(input)) {
        if (settingsType[key]) {
            input[key] = getTypedSetting (key, value)
        }
    }
}
export default {
    namespaced: true,
    state : () => {
        let cookieData = cookie.get(settingsCookieName);
        let cookieSettings = false;
        if (cookieData) {
            try {
                cookieSettings = JSON.parse(cookieData);
            } catch (e) {
            
            }
        }
        return {
            settings : cookieSettings ? {...defaultSettings, ...cookieSettings} : defaultSettings,
            visited: false,
        }
    },
    getters : {
        settings : (state) =>  state.settings,
        visited: state => state.visited,
    },
    mutations: {
        updateSettings: (state, payload) => {
            if (typeof payload === 'string') {
                payload = JSON.parse(payload);
            }
            // disallow ****ed up payload
            if (typeof payload !== 'object' || payload === null) {
                return;
            }
          
            enforceSettingsType(payload);
        
            // enforce settings types
            state.settings = payload;
            cookie.set(settingsCookieName, payload);
        },
        clearSettings : (state, payload) => {
            state.settings = {...defaultSettings};
            cookie.set(settingsCookieName, state.settings);
        },
        resetSettings : (state, payload) => {
            state.settings = {...defaultSettings};
            cookie.set(settingsCookieName, state.settings);
        },
        setVisited (state, val) {
            state.visited = val;
        }
    }
};
