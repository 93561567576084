const goodPassword = {
    $validator: (value) => {
        if ( ! value) {
            return true;
        }
        
        if (typeof value === 'number') {
            value =  String(value);
        }
        
        return value.length >= 8;
    },
};

export default goodPassword;
